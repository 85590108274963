var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"VPTY8pjwHNtNC_YuMP4Y-"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import posthog from 'posthog-js';
import config from '@lib/config';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const options = {
  dsn: SENTRY_DSN || 'https://c304bfbd2de37691ac4230725934f49e@o4507056239607808.ingest.de.sentry.io/4507147504189520',
  tracesSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [config.coreApi, 'new.yoga.drdropin.no', 'new.yoga-staging.drdropin.no', /^\//]
    }),
    new posthog.SentryIntegration(
      posthog,
      'drdropin-tech',
      4507147504189520
    )
  ],
  environment: config.environment,
  release: config.release
};

if (config.environment === 'development') {
  options.tracesSampleRate = 0;
  options.integrations = [
    new Sentry.Integrations.Breadcrumbs({
      console: false,
      dom: false,
    })
  ];
}

Sentry.init(options);
